<template>
    <div id="calendar" class="calendar">
        <header>
            <div class="current-date">
                <div class="day-before" @click="prevDay()"></div>
                <div class="value" @click="toogle()">{{ date | formatDate }}</div>
                <div class="day-next" @click="nextDay()"></div>
            </div>
            <div v-show="displayed" class="month-nav">
                <div class="month">{{ getString('calendar.months')[date.format('M') - 1] }} {{ getString('calendar.separator') }} {{ date.format('YYYY') }}</div>
                <div class="btn-prev" @click="prevMonth()"></div>
                <div class="btn-next" @click="nextMonth()"></div>
            </div>
        </header>

        <div v-show="displayed" class="grid">
            <div class="days-header">
                <span>{{ getString('calendar.day_monday') }}</span>
                <span>{{ getString('calendar.day_tuesday') }}</span>
                <span>{{ getString('calendar.day_wednesday') }}</span>
                <span>{{ getString('calendar.day_thursday') }}</span>
                <span>{{ getString('calendar.day_friday') }}</span>
                <span>{{ getString('calendar.day_saturday') }}</span>
                <span>{{ getString('calendar.day_sunday') }}</span>
            </div>
            <div class="month-body">
                <div v-for="(item, index) in days" :id="'cal-row-' + index" class="week" :key="index">
                    <div v-for="(day, j) in item" pos="j" class="day" :class="{ active: day.selected, 'current-day': day.date == today, nextMonth: day.month > m, prevMonth: day.month < m }" :key="j" @click="select(day.date)">
                        <span class="day-value">{{ day.day }}</span>
                        <div class="events">
                            <div class="note"></div>
                            <div class="task"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        year: { type: Number, default: new Date().getFullYear() },
        month: { type: Number, default: new Date().getMonth() },
        day: { type: Number, default: new Date().getDate() },
        selected: { type: String, default: moment().format('YYYY-MM-DD') },
        condensed: { type: Boolean, default: false },
        openPopup: { type: Boolean, default: false },
        doToggle: { type: Boolean, default: true },
        goToFuture: { type: Boolean, default: true }
    },
    data() {
        return {
            today: moment().format('YYYY-MM-DD'),
            date: moment(this.selected),
            calendar: moment(this.selected),
            displayed: !this.condensed
            // TODO - Events for each day (red/green marker)
        }
    },
    computed: {
        y() {
            return this.date.format('YYYY')
        },
        m() {
            return this.date.format('M')
        },
        d() {
            return this.date.format('D')
        },
        structure() {
            // Triggered every time month is changed (dependence on this.calendar changes)
            var date = this.calendar
            var startOfMonth = date.clone().startOf('month')
            var endOfMonth = date.clone().endOf('month')
            var firstCalDate = startOfMonth.clone().startOf('isoWeek')
            var lastCalDate = endOfMonth.day() == 0 ? endOfMonth.clone() : endOfMonth.clone().day(7)

            var days = []
            var i = new Date(firstCalDate.format('YYYY-MM-DD'))
            var until = new Date(lastCalDate.format('YYYY-MM-DD'))
            var count = 0
            var week = 0
            days.push([])

            while (i <= until) {
                if (count == 7) {
                    days.push([])
                    week++
                    count = 0
                }
                var current_date = moment(i)
                days[week].push({
                    date: current_date.format('YYYY-MM-DD'),
                    day: i.getDate(),
                    month: i.getMonth() + 1,
                    year: i.getFullYear(),
                    selected: current_date.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')
                })
                i.setDate(i.getDate() + 1)
                count++
            }

            return days
        },
        days() {
            // Triggered every time date is changed (dependence on this.date changes)
            var structure = this.structure
            var to_compare = this.date.format('YYYY-MM-DD')

            for (var i in structure) {
                for (var k in structure[i]) {
                    structure[i][k].selected = structure[i][k].date == to_compare
                }
            }

            return structure
        },
        months() {
            return i18n.t('calendar_months')
        }
    },
    filters: {
        formatDate: function(date) {
            return moment(date).format('dddd DD')
        },
        formatMonth: function(date) {
            var months = i18n.t('calendar_months')
            return months[date.getMonth() - 1]
        }
    },
    methods: {
        select(date) {
            if (this.date.format('YYYY-MM-DD') != moment(date).format('YYYY-MM-DD')) {
                var refresh = this.date.format('MM') != moment(date).format('MM')
                this.date = moment(date)
                if (refresh) {
                    log('refresh - ' + this.calendar.format('YYYY-MM-DD'))
                    this.calendar = this.date
                }
                this.$emit('select', this.date.format('YYYY-MM-DD'), refresh)
            }
        },
        prevDay() {
            this.select(
                this.date
                    .clone()
                    .add(-1, 'days')
                    .format('YYYY-MM-DD')
            )
        },
        nextDay() {
            let now = moment()
            let next = this.date.clone().add(1, 'days')
            if (this.goToFuture || next.isBefore(now)) {
                this.select(next.format('YYYY-MM-DD'))
            }
        },
        prevMonth() {
            this.select(
                this.date
                    .clone()
                    .add(-1, 'months')
                    .format('YYYY-MM-DD')
            )
        },
        nextMonth() {
            let now = moment()
            let next = this.date.clone().add(1, 'months')
            if (this.goToFuture || next.isBefore(now)) {
                this.select(next.format('YYYY-MM-DD'))
            }
        },

        getString(str) {
            return i18n.t(str)
        },

        toogle() {
            if (this.doToggle) {
                if (this.openPopup) {
                    log('open in popup')
                    if (this.condensed) {
                        this.displayed = !this.displayed
                    }
                } else {
                    if (this.condensed) {
                        this.displayed = !this.displayed
                    }
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$calendar-click: 30px;
$calendar-click-double: $calendar-click * 2;

.calendar {
    display: block;
    height: 100%;
}

.calendar header {
    width: 100%;
    height: auto;
    max-height: $calendar-click-double;

    .current-date,
    .month-nav {
        overflow: hidden;
        height: $calendar-click;
        display: block;
    }

    .current-date {
        .value {
            @include font-size(sm);
            font-family: $text-light;
            text-transform: capitalize;
            float: left;
            width: calc(100% - #{$calendar-click-double});
            height: $calendar-click;
            line-height: $calendar-click;
            text-align: center;
        }

        .day-before,
        .day-next {
            @include interaction();
            @include border-radius($calendar-click);
            @include background($size: 15px);
            display: block;
            width: $calendar-click;
            height: $calendar-click;

            &:hover {
                background-color: hover($default-bg-color, false);
            }
        }
        .day-before {
            float: left;
            background-image: img('left_dark.svg');
        }
        .day-next {
            float: right;
            background-image: img('right_dark.svg');
        }
    }

    .month-nav {
        .month {
            @include font-size(s);
            font-family: $text;
            float: left;
            width: calc(100% - #{$calendar-click-double});
            height: $calendar-click;
            line-height: $calendar-click;
            text-align: left;
            padding-left: 10px;
        }

        .btn-next,
        .btn-prev {
            @include interaction();
            @include background($size: 12px);
            display: block;
            width: $calendar-click;
            height: $calendar-click;

            &:hover,
            &:active {
                // color: #f9f9f9;
            }
        }
        .btn-prev {
            float: left;
            background-image: img('left_dark.svg');
        }
        .btn-next {
            float: right;
            background-image: img('right_dark.svg');
        }
    }
}

.calendar .grid {
    height: calc(100% - #{$calendar-click-double});
    display: block;

    .days-header {
        @include display-flex();
        @include align-items();
        @include justify-content();
        @include border-radius(3px);
        height: $calendar-click - 10px;
        width: 100%;
        background-color: #fff;

        span {
            @include font-size(s);
            color: #444;
            width: 100%;
            min-width: $calendar-click;
            max-width: calc(100% / 7);
            text-transform: uppercase;
            text-align: center;
            font-family: $text-bold;
            letter-spacing: -1px;
        }
    }

    .month-body {
        height: calc(100% - #{$calendar-click} + 10px);
        width: 100%;
    }

    .month-body .week {
        @include display-flex();
        @include align-items();
        @include justify-content();

        width: 100%;
        height: 100%;
        min-height: $calendar-click;
        max-height: calc(100% / 6);
    }

    .month-body .day {
        @include interaction();
        @include display-inline-flex();
        @include align-items($val: flex-start);
        @include justify-content();
        @include flex-wrap($w: wrap);
        @include font-size(s);
        position: relative;
        height: 100%;
        width: 100%;
        min-width: $calendar-click;
        max-width: calc(100% / 7);
        text-align: center;
        overflow: hidden;
        padding-top: 2px;

        &:hover {
        }

        &.nextMonth,
        &.prevMonth {
            .day-value {
                color: #b5b5b5;
            }
        }
        &.nextMonth.disabled,
        &.prevMonth.disabled {
            .day-value {
                color: #ccc;
            }
        }

        &.active {
            .day-value {
                font-weight: bold;
                color: #fff;
                background-color: $main-dark;
            }
        }
        &.current-day {
            .day-value {
                font-weight: bold;
                color: $main-text;
                background-color: $main-t70;
            }
        }
        &.good-day {
            .day-value {
                border: 2px solid $done;
            }
        }
        &.bad-day,
        &.alert-task {
            .day-value {
                border: 2px solid $error;
            }
        }
        &.warning-day {
            .day-value {
                border: 2px solid $warning;
            }
        }

        .day-value {
            @include font-size(s);
            @include border-radius(100%);
            color: #666;
            font-family: $text-light;
            text-align: center;
            line-height: 1.5rem;
            height: calc(#{$calendar-click} - 7px);
            width: calc(#{$calendar-click} - 7px);
            display: block;
        }

        .events {
            text-align: center;
            height: 7px;
            display: block;
            width: 100%;
            line-height: 5px;

            .task,
            .note {
                @include border-radius(50%);
                width: 5px;
                height: 5px;
                display: none;
                margin: 0px 1px 3px 1px;
            }

            .note.active {
                background-color: $error;
                display: inline-block;
            }

            .note.complete {
                background-color: $done;
                display: inline-block;
            }

            .task.active {
                background-color: $error;
                display: inline-block;
            }
        }
        &.active .events {
            // display: none;
        }
    }
}
</style>
